@import (reference) '@/styles/variables.less';
@import (reference) '@/styles/mixins.less';
.coupon-modal {
  :global {
    .ant-modal {
      line-height: unset !important;
    }

    .ant-modal-content {
      padding: 24px 40px !important;
      border-radius: 12px !important;
      background: linear-gradient(180deg, #ebeeff 0%, #ffffff 100%);
      box-shadow: 0px 5px 32px 2px rgba(0, 0, 0, 0.12);

      .ant-modal-header {
        background-color: unset;
      }

      .ant-modal-title {
        text-align: center;
      }

      .ant-modal-body {
        line-height: unset !important;
      }
    }
  }

  .ok-button,
  .cancel-button {
    width: 120px;
    height: 44px;
    margin-inline-start: 16px !important;
    font-weight: 600;
    font-size: 16px;
  }
}
