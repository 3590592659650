@import (reference) '@/styles/variables.less';
@import (reference) '@/styles/mixins.less';

.login-modal {
  color: red;

  :global {
    .ant-modal-content {
      padding: 0;
      border-radius: 28px;
    }
  }

  .login-content {
    display: flex;

    .img {
      .size(261px,508px);
    }

    .right {
      flex: 1;

      .flex-col();

      .title {
        .flex-row();
        justify-content: space-evenly;
        .wx {
          width: 22px;
          height: 22px;
        }

        .wx-text {
          margin-left: 8px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 18px;
          color: #2c2c2c;
          text-align: left;
          font-style: normal;
        }
      }

      .qr-wrap {
        margin-top: 32px;
        width: 252px;
        height: 252px;
        background: #ffffff;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        .flex-row();

        .qr-code {
          .size(212px,214px);
        }

        .loading-icon {
          .size(24px);
        }
      }

      .info {
        margin-top: 24px;
        width: 342px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        line-height: 17px;
        text-align: center;
        font-style: normal;

        .link {
          color: #666666;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
