@import (reference) './variables.less';

.fit() {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.absolute-fit() {
  .fit();
  position: absolute;
}

.fixed-fit() {
  .fit();
  position: fixed;
}

.absolute-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.text-ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.text-m-ellipses(@line) {
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: @line;
  -webkit-box-orient: vertical;
}

.prettier-scroll-bar(@width: 6px, @height: 6px, @color: #0000002f, @color-hover: #00000062) {
  overflow-y: auto !important;
  overscroll-behavior: contain;

  &::-webkit-scrollbar {
    height: @height;
    width: @width;
    margin-right: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: @color;
    border-radius: 4px;
    cursor: default;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: @color-hover;
  }
}

// 注意 display:flex ,inline flex 此 mixins 不生效
.text-overflow(@line: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  // white-space: nowrap;
  word-wrap: break-word;
  -webkit-line-clamp: @line;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.clickable() {
  cursor: pointer;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: transparent;
}

.link() {
  .clickable();
  text-decoration: none;
  color: @color-primary;

  &:hover,
  &:active {
    color: @color-primary-hover;
  }
}

.error-link() {
  .clickable();
  color: @color-danger;

  &:hover {
    color: @color-danger-hover;
  }
}


.size(@w) {
  width: @w;
  height: @w;
}

.size(@w, @h) {
  width: @w;
  height: @h;
}

.circle(@s) {
  .size(@s, @s);
  border-radius: @s;
}

.h-lh(@h) {
  height: @h;
  line-height: @h;
}

.dashed-link(@color: #606060) {
  .clickable();
  display: inline-flex;
  color: @color;
  border-bottom: 1px dashed @color;

  &:hover {
    color: @color-primary-hover;
  }
}

.box-shadow() {
  background: #fff;
  // box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 3.2px 10.2px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.06), 0px 3.2px 10.2px rgba(0, 0, 0, 0.06);
}

.line-height(@height) {
  display: flex;
  align-items: center;
  height: @height;
}

.global-font() {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, 'Noto Sans JP',
    'Noto Sans KR', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

.flex-row() {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-col() {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.markdown-overwrite() {
  font-size: 15px;
  line-height: 1.6;
  // padding-bottom: 10px;

  pre {
    margin-top: 10px;
    overflow: hidden;
  }

  p {
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  &>p:not(:last-child) {
    margin-bottom: 10px;
  }

  pre code {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  pre code.hljs {
    padding: 0;
    background-color: transparent;
  }

  ol {
    list-style-type: auto;
  }

  ul {
    list-style-type: disc;
  }

  img {
    width: 100%;
  }

  input {
    &::placeholder {
      color: #919eab;
    }
  }

  textarea {
    &::placeholder {
      color: #919eab;
    }
  }

  a {
    text-decoration: underline;
  }
}

.textarea-overwrite() {
  .prettier-scroll-bar();
  .pre-wrap();
  border: 1.5px solid #e6eaf2;
  border-radius: 4px;
  box-shadow: unset !important;
  padding: 8px 12px;
  outline: 0;
  font-size: 14px;
  color: #212b36;
  line-height: 24px;

  &::placeholder {
    color: #919eab;
  }

  &:hover {
    border-color: rgba(56, 114, 224, 0.48);
  }

  &:focus {
    border-color: @color-primary;
  }

  // disabled
  &[disabled] {
    background-color: #f5f7fa;
    border-color: #e6eaf2;
    color: #919eab;
    cursor: not-allowed;
  }
}

.input-overwrite() {
  .pre-wrap();
  border: 1.5px solid #e6eaf2;
  border-radius: 4px;
  box-shadow: unset !important;
  padding: 4px 12px;
  outline: 0;
  font-size: 14px;
  color: #212b36;

  &::placeholder {
    color: #919eab;
  }

  &:hover {
    border-color: rgba(56, 114, 224, 0.48);
  }

  &:focus {
    border-color: @color-primary;
  }

  // disabled
  &[disabled] {
    background-color: #f5f7fa;
    border-color: #e6eaf2;
    color: #919eab;
    cursor: not-allowed;
  }
}

.normalize() {
  margin: 0;
  padding: 0;

  * {
    box-sizing: border-box;
    //font-weight: 400;
    direction: ltr;
  }

  ul,
  ol {
    list-style-type: none;
  }

  img {
    vertical-align: middle;
    border-style: none;
  }

  a {
    .link();
    background-color: transparent;
  }

  textarea {
    color: @color-text;
  }

  b,
  strong {
    font-weight: bolder;
  }

  div,
  dl,
  dt,
  dd,
  ul,
  ol,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  code,
  form,
  fieldset,
  legend,
  input,
  textarea,
  p,
  blockquote,
  th,
  td,
  hr,
  button,
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    margin: 0;
    padding: 0;
  }

  :global(.ant-switch) {
    background: #c9c9c9;
  }

  :global(.ant-switch:hover:not(.ant-switch-disabled)) {
    background: #8a8a8a;
  }
}

.highlight() {
  padding: 0;
  background-color: unset;
  color: @color-primary;
}

.mask() {
  background: rgba(0, 0, 0, 0.4);
}

.round-close-icon-btn(@size, @c-border: #b7babf) {
  cursor: pointer;
  font-size: 0;
  .size(@size);
  .flex-row;
  border: 1px solid @c-border;
  background-color: white;
  border-radius: 100px;
  transition: all 0.1s ease;
  color: #989b9d;

  &:hover {
    @color-red: rgb(231, 106, 106);
    background-color: @color-red;
    border-color: @color-red;
    color: white;
  }
}

.ant-popover-unset() {
  padding: unset;

  :global(.ant-popover-arrow) {
    display: none;
  }

  :global(.ant-popover-inner) {
    background: unset;
    border-radius: unset;
    box-shadow: unset;
  }

  :global(.ant-popover-inner-content) {
    padding: unset;
    color: unset;
  }
}

.ant-collapse-unset() {
  :global(.ant-collapse-header) {
    padding: 0 !important;
  }

  :global(.ant-collapse-header-text) {
    color: #212b36;
    font-weight: bold;
    font-size: 14px;
    overflow: hidden;
  }

  :global(.ant-collapse-content-box) {
    padding: 0 !important;
  }

  :global(.ant-collapse-expand-icon) {
    height: 20px !important;
    padding-inline-start: 4px !important;
  }
}

.pre-wrap() {
  word-wrap: break-word;
  white-space: pre-wrap;
}

.button-overwrite() {
  outline: none;
  border: none;
}

.pretty-scroll-bar(@width: 6px, @height: 6px) {
  &::-webkit-scrollbar {
    .size(@width, @height);
  }

  &::-webkit-scrollbar-thumb {
    .size(6px);
    border-radius: 4px;
    background-color: #d5d5d5;
    cursor: pointer;

    &:hover {
      background-color: #ccc;
    }
  }
}

//pseudo arrows
.pseudo-arrow(@size: 10px) {
  box-sizing: content-box;
  content: "";
  display: inline-block;
  .size(0);
  border: @size solid transparent;
}

.pseudo-arrow-up(@size: 10px, @color: white) {
  .pseudo-arrow(@size);
  border-bottom-color: @color;
}

.pseudo-arrow-down(@size: 10px, @color: white) {
  .pseudo-arrow(@size);
  border-top-color: @color;
}

.pseudo-arrow-left(@size: 10px, @color: white) {
  .pseudo-arrow(@size);
  border-right-color: @color;
}

.pseudo-arrow-right(@size: 10px, @color: white) {
  .pseudo-arrow(@size);
  border-left-color: @color;
}