@import (reference) '@/styles/variables.less';
@import (reference) '@/styles/mixins.less';

.coupon-list-box {
  .content {
    margin-top: 16px;
    height: 443px;
    overflow-y: auto;
    .prettier-scroll-bar();
    margin-right: -28px;
    padding-right: 28px;
    &.with-footer {
      height: 419px;
    }

    .coupon-tips {
      background: linear-gradient(136deg, #fefde0 0%, #fffeea 15%, #fefde0 100%);
      border-radius: 8px;
      border: 1px solid #fff7c6;
      width: 100%;
      padding: 12px 0 20px 20px;
      font-weight: 400;
      font-size: 14px;
      color: #723d15;
      line-height: 20px;
    }

    .tab-box {
      width: 168px;
      background: #f8f8f8;
      border-radius: 10px;
      border: 1px solid #e9e9e9;
      display: flex;
      margin-top: 16px;

      .tab-item {
        width: 50%;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        height: 36px;
        .flex-row();
        cursor: pointer;
        border-radius: 8px;

        &.active {
          background: #ffffff;
          box-shadow: 0px 0px 7px 0px rgba(92, 92, 92, 0.08);
          color: #333333;
          font-weight: 600;
        }
      }
    }

    .coupon-list {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      row-gap: 16px;
      column-gap: 20px;

      .empty {
        width: 100%;
        .flex-col();
        margin-top: 40px;

        img {
          .size(169px, 105px);
          margin-bottom: 24px;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
        }
      }

      .coupon-item {
        width: 405px;
        position: relative;
        z-index: 2;
        .flex-row();
        cursor: pointer;

        &.disabled {
          opacity: 0.5;
          cursor: unset;
        }

        .bg {
          width: 412px;
          position: absolute;
          top: -4px;
          left: 0;
          z-index: -1;
        }

        .price-box {
          width: 90px;
          height: 80px;
          .flex-row();
          font-size: 34px;
          color: #ffffff;
          line-height: 42px;
          font-weight: 600;

          p {
            display: flex;
            align-items: baseline;
          }

          span {
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
            line-height: 20px;
          }
        }

        .coupon-right {
          width: 312px;
          height: 80px;
          padding: 0 16px 0 20px;
          .flex-row();
          justify-content: space-between;

          .coupon-info {
            .title {
              font-weight: 600;
              font-size: 16px;
              color: #222222;
              line-height: 22px;
            }

            .expired {
              font-weight: 400;
              font-size: 12px;
              color: #666666;
              line-height: 17px;
              margin-top: 4px;
            }
          }

          .radio-box {
            img {
              .size(16px);
            }

            .hover,
            .checked {
              display: none;
            }

            &.active {
              .hover,
              .default {
                display: none;
              }

              .checked {
                display: block;
              }
            }

            &:not(.active):hover {
              .default {
                display: none;
              }

              .hover {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
