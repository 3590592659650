@import (reference) '../../styles/mixins.less';
@import "../../styles/common-class.less";

.page-header {
  width: 100%;
  height: 62px;
  padding: 0 120px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  .flex-row();
  justify-content: space-between;
  transition: box-shadow 0.2s ease-in-out;

  .hide {
    display: none !important;
  }

  &.has-scroll {
    z-index: 1001;
    background-color: #fff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  }

  .left-box {
    .flex-row();
    justify-content: flex-start;

    .logo {
      height: 36px;
    }

    .flag {
      height: 24px;
      background: #fbf2ff;
      border-radius: 5px;
      border: 1px solid #ebd5ff;
      padding: 0 8px;
      font-size: 14px;
      font-weight: normal;
      color: #a356f1;
      line-height: 23px;
      position: relative;
      z-index: 1;
      left: -32px;
    }
  }

  .right-box {
    .flex-row();
    justify-content: flex-end;

    .translation-btn {
      height: 36px;
      border-radius: 10px;
      // border: 1px solid @color-primary;
      cursor: pointer;
      padding: 0px 22px;
      margin-left: 40px;
      background: linear-gradient(227deg, #ba87ff 0%, #a15cff 100%);

      &.oem-lenovo {
        background: linear-gradient(227deg, #9387FF 0%, #7F5CFF 100%);
      }

      .icon {
        .size(18px);
        margin-right: 5px;
      }

      .text {
        color: #fff;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        // color: @color-primary;
        line-height: 22px;
      }

      &:hover {
        // background: #faf6ff;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
      }
    }

    .link-btn {
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
      margin-left: 40px;
      white-space: nowrap;
      word-break: keep-all;
      .clickable();

      &-icon {
        .size(27px);
      }

      &:hover {
        color: #444555;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    .link-btn-help {
      position: relative;

      .help-box {
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
        width: 140px;
        height: 140px;
        padding: 2px;
        background: white;
        border-radius: 6px;
        box-shadow: 0px 2px 10px 0px rgba(93, 93, 93, 0.15);
        opacity: 0;
        transition: opacity 0.3s ease;
        visibility: hidden;

        .qr-code {
          .size(100%);
        }

        &.visible {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .btn {
      padding: 0 24px;

      margin-left: 40px;
      transition: all 0.3s ease;
      white-space: nowrap;
      word-break: keep-all;
      .clickable();
      .flex-row();
      width: 104px;
      height: 36px;
      border-radius: 10px;
      border: 1px solid #4B4B4B;
      font-weight: 400;
      font-size: 16px;
      color: #4B4B4B;

      &:hover {
        color: white;
        background: linear-gradient( 28deg, #363636 0%, #555556 100%);
      }
    }


  }
}

.user-info-box {
  position: relative;
  cursor: pointer;
  margin-left: 40px;

  &:hover {
    .menu-box-wrapper {
      display: block
    }
  }

  .avatar {
    .size(32px);
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.25);
  }

  .icon-arrow {
    .size(5px, 5px);
    object-fit: contain;
    margin-left: 6px;
    position: absolute;
    bottom: 3px;
    right: -4px;
  }

  .menu-box-wrapper {
    width: 127px;
    position: absolute;
    right: 0%;
    padding-top: 14px;
    top: 68%;
    transition: opacity 0.3s ease;
    display: none;
  }

  .menu-box {
    background: white;
    border-radius: 4px;
    box-shadow: 0px 2px 10px 0px rgba(93, 93, 93, 0.15);
    border-radius: 10px;
    background-color: #fff;
    color: #333;
    padding: 4px 0;
    overflow: hidden;
    .flex-col();

    .menu-li {
      word-break: keep-all;
      white-space: nowrap;
      .clickable();
      width: 100%;
      padding: 0 26px;
      .flex-row();
      justify-content: flex-start;
      .h-lh(44px);

      &:hover {
        background: #EBEEFF;
      }

      .icon {
        .size(22px);
      }

      .text {
        font-size: 13px;
        font-weight: 400;
        color: #1c1e22;
        line-height: 18px;
        margin-left: 4px;
      }
    }

    .line {
      width: 76px;
      height: 1px;
      background-color: #f2f2f2;
    }
  }
}