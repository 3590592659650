@import (reference) '@/styles/variables.less';
@import (reference) '@/styles/mixins.less';

.banner-box {
  position: relative;

  .banner-box-img {
    width: 100%;
  }

  .banner-box-btn-img {
    width: 133px;
    position: absolute;
    cursor: pointer;

    img {
      width: 100%;
    }

    .banner-box-btn-img-hover {
      display: none;
    }

    &:hover {
      .banner-box-btn-img-hover {
        display: block;
      }

      .banner-box-btn-img-normal {
        display: none;
      }
    }
  }

  .countdown-box {
    position: absolute;
    .flex-row();
    font-weight: 400;
    font-size: 23px;
    color: #666666;

    &.only-d span {
      width: 36px;
      height: 36px;
      background: none;
      border: none;
      font-weight: bold;
      font-size: 30px;
      color: #fdfdfd;
      .flex-row();
    }

    span {
      width: 38px;
      height: 38px;
      background: linear-gradient(180deg, #fff5d3 0%, #ffe7a8 100%);
      border-radius: 10px;
      border: 2px solid #fff6e0;
      font-weight: 600;
      font-size: 25px;
      color: #e87b29;
      .flex-row();
      margin: 0 2px;
      img{
        height: 100%;
        &+img{
          margin-left: -2px;
        }
      }
    }
  }

  &.sidebar-banner{
    .banner-box-inner{
      width: 100%;
      position: relative;
      height: 50px;
      overflow: hidden;
      border-radius: 12px;
      max-width: 760px;
      .banner-box-bg{
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
      }
      .banner-box-img{
        height: 100%;
        width: auto;
        position: relative;
      }

      .countdown-box {
        font-size: 16px;
        color: #666666;

        &.only-d span {
          width: 30px;
          height: 30px;
          background: none;
          border: none;
          font-weight: bold;
          font-size: 16px;
          color: #FDFDFD;
          .flex-row();
        }

        span {
          width: 22px;
          height: 23px;
          background: linear-gradient(180deg, #FFF5D3 0%, #FFE7A8 100%);
          border-radius: 5px;
          border: 2px solid #FFF6E0;
          font-weight: 600;
          font-size: 14px;
          color: #E87B29;
          .flex-row();
          margin: 0 2px;
          img{
            height: 100%;
            &+img{
              margin-left: -1px;
            }
          }
        }
      }
    }
  }
}
