@import (reference) '@/styles/variables.less';
@import (reference) '@/styles/mixins.less';

.pay-qr-dialog {
  :global {

    .ant-modal {
      line-height: unset !important;
    }

    .ant-modal-content {
      padding: 0px !important;
      border-radius: 28px !important;

      .ant-modal-body {
        line-height: unset !important;
      }
    }
  }

  .pay-qr-dialog-content {
    padding: 24px 0;
    height: 431px;
    .flex-col();
    justify-content: flex-start;
    background: linear-gradient(180deg, #EBEEFF 0%, #FFFFFF 100%);
    box-shadow: 0px 5px 32px 2px rgba(0, 0, 0, 0.12);
    border-radius: 12px;

    >.title {
      font-size: 20px;
      font-weight: 600;
      color: #000000;
      line-height: 28px;
    }

    >.plan-title {
      font-weight: 600;
      font-size: 18px;
      color: #333333;
      line-height: 25px;
      margin-top: 20px;
      text-align: center
    }

    .qr-box {
      .size(200px);
      margin-top: 16px;
      .flex-col();
      position: relative;

      >img {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }

      .reload-box {
        .size(100%);
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(255, 255, 255, 0.94);
        justify-content: center;
        cursor: pointer;

        img {
          width: 16px;
          margin-bottom: 4px;
        }

        span {
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 18px;
        }
      }
    }

    >.pay-method {
      .flex-row();
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      margin-top: 16px;

      img {
        width: 18px;
        margin: 0 4px;
      }
    }

    .invoice-box {
      .flex-row();
      font-weight: 400;
      font-size: 13px;
      color: #999999;
      margin-top: 8px;
      img{
        width: 13px;
        margin-right: 8px;
      }
    }

  }
}