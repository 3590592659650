@import (reference) '@/styles/variables.less';
@import (reference) '@/styles/mixins.less';

.pricing-page-v-1 {
  background: linear-gradient(172deg, #F9EAD2 0%, #FAEFDF 9%, #FFFFFF 100%);
  min-height: 100%;
  width: 100%;
  overflow-x: hidden;
  position: relative;

  >.bg-img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1738px;
  }

  .content {
    width: 100%;
    padding-top: 70px;
    position: relative;
    padding-bottom: 32px;

    .flex-col();
    justify-content: flex-start;

    .buy-vip-content {
      margin-top: 24px;
      .flex-row();
      width: 910px;
      align-items: flex-start;
      box-shadow: 0px 2px 26px 0px rgba(134, 134, 134, 0.08);
      border-radius: 13px;
      border: 2px solid #FFFFFF;
      position: relative;
      background: #FFFFFF;
      height: 633px;

      .bg {
        .size(100%);
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
      }

      .login-btn {
        width: 88px;
        height: 32px;
        background: linear-gradient(141deg, #FBE8B3 0%, #F8D193 100%);
        border-radius: 20px;
        border: none;
        font-size: 14px;
        color: #614121;

        &:hover {
          background: linear-gradient(141deg, #F9E2A3 0%, #EFBE6E 100%);
          color: #412B14;
        }
      }

      >.left {
        width: 636px;
        padding: 16px 40px 28px;

        .user-info-box {
          .flex-row();
          justify-content: space-between;

          .user-info {
            .flex-row();
            justify-content: flex-start;

            >img {
              .circle(36px);
              margin-right: 8px;
            }

            .user-name {
              .flex-row();
              font-weight: 600;
              font-size: 13px;
              color: #333333;
              margin-bottom: 3px;
              justify-content: flex-start;
              position: relative;
              width: fit-content;
              img {
                height: 40px;
                position: absolute;
                right: -49px;
                top: -7px;
              }
            }

            .user-expired {
              font-weight: 400;
              font-size: 12px;
              color: rgba(51, 51, 51, 0.45);
              line-height: 17px;
            }
          }

          .coupon-btn {
            width: 89px;
            height: 28px;
            background: #FFFFFF;
            border-radius: 14px;

            font-weight: 400;
            font-size: 14px;
            color: #666666;
            cursor: pointer;
            border: 1px solid #EEF0F4;
            box-shadow: none;

            img {
              width: 18px;
            }

          }
        }

        .buy-record-box {
          width: 100%;
          height: 32px;
          background: #FFF7E2;
          box-shadow: inset 0px 1px 3px 0px rgba(255, 255, 255, 0.43);
          border-radius: 5px;
          border: 1px solid #FFF0C7;
          padding: 0 16px;
          overflow: hidden;
          margin-top: 12px;

          .record-item {
            width: 100%;


            div {
              img {
                width: 17px;
                margin-right: 4px
              }

              .size(100%, 32px);
              font-weight: 600;
              font-size: 13px;
              color: #995A00;
              line-height: 18px;
              .flex-row();
              justify-content: flex-start;
            }
          }
        }

        .plan-list {
          margin-top: 18px;
          display: flex;
          column-gap: 20px;
          min-height: 143px;

          .plan-item-box {
            border: 1px solid transparent;
            width: 124px;
            height: 143px;
            position: relative;

            &.active, &:hover {
              border: none;

              .plan-item {
                color: #995A00;
                background: linear-gradient(327deg, #FBE8B3 0%, #F8D193 100%);
                border: 2px solid #F6C561;

                .pre-month-price {
                  color: #614121;
                }

                .plan-top {
                  background: linear-gradient(327deg, #FEF6DC 0%, #FFF7E1 63%, #FCECBE 87%, #FCE9B6 100%);
                }
              }
            }
          }

          .recommend-box {
            background: linear-gradient(332deg, #363636 0%, #6E5A14 100%);
            border-radius: 0px 9px 0px 9px;
            position: absolute;
            right: -1px;
            top: -1px;
            font-weight: 400;
            font-size: 12px;
            color: #FBDEBE;
            padding: 0px 9px;
            height: 18px;
            line-height: 18px;
            z-index: 3;
          }

          .plan-item {
            .size(100%, 100%);
            .flex-col();
            justify-content: flex-start;
            position: relative;
            z-index: 2;
            color: #333333;
            cursor: pointer;
            background: #FDEFD3;
            border-radius: 12px;
            border: 1px solid #F4ECDA;

            .plan-top {
              .flex-col();
              justify-content: flex-start;
              flex: 1;
              padding-top: 18px;
              border-radius: 12px;
              background-color: white;
              width: 100%;
            }

            .plan-title {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
            }

            .price-box {
              margin-top: 15px;
              font-weight: bold;
              font-size: 21px;
              line-height: 25px;

              span {
                font-size: 31px;
              }
            }

            .pre-month-price {
              width: 100%;
              padding: 2px 0;
              text-align: center;
              line-height: 18px;
              font-weight: 400;
              font-size: 13px;
              color: #886231;
            }
          }
        }

        .subscription-tips {
          margin-top: 10px;
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          line-height: 17px;

          &.hidden {
            visibility: hidden;
          }
        }

        .func-box {
          margin-top: 16px;
          position: relative;
          width: 556px;
          z-index: 2;

          .edu-box {
            position: absolute;
            right: 17px;
            bottom: 20px;
            padding-bottom: 4px;
            width: 254px;
            height: 49px;
            background: #FFF6E4;
            border-radius: 12px;
            z-index: -1;
            .flex-row();
            align-items: end;
          }

          .edu-inner {
            .flex-row();
            font-weight: 400;
            font-size: 12px;
            color: #937249;
            cursor: pointer;


            &:hover {
              color: #694E25;

              .hover {
                display: block;
              }

              .default {
                display: none;
              }

            }

            .edu-icon {
              height: 15px;
              margin-right: 4px;
            }

            .hover {
              display: none;
            }

            .arrow-right {
              width: 13px;
              margin-left: 4px;
            }
          }

          >.func-box-tabs {
            .flex-row();
            justify-content: center;
            width: 231px;
            height: 34px;
            background: #FFFFFF;
            box-shadow: 0px 0px 12px 0px rgba(113, 113, 113, 0.14);
            border-radius: 18px;
            border: 1px solid #FFFDF6;
            margin: 0 auto;
            position: relative;
            z-index: 3;

            .func-box-tab {
              width: 50%;
              height: 100%;
              border-radius: 18px;
              .flex-row();
              cursor: pointer;

              font-weight: 600;
              font-size: 14px;
              color: #888888;

              &:hover:not(.active) {
                color: #605F5F;

                img.tab-img {
                  display: none;
                }

                img.tab-hover-img {
                  display: block;
                }
              }

              &.active {
                color: #FBDEBE;
                background: linear-gradient(202deg, #363636 0%, #555556 100%);

                img.tab-img {
                  display: none;
                }

                img.tab-active-img {
                  display: block;
                }
              }

              img {
                width: 22px;
                margin-right: 6px;

                &.tab-active-img, &.tab-hover-img {
                  display: none;
                }
              }
            }
          }

          >.func-box-content {
            padding: 42px 16px 34px;
            margin-top: -16px;
            justify-content: space-between;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: 280px;
            background: linear-gradient(146deg, #FDEFD3 0%, #FFF7E8 39%, #FDEFD3 100%);
            border-radius: 13px;
            border: 1px solid #F4ECDA;
            position: relative;
            z-index: 2;

            &.hide {
              display: none;
            }

            .func-item {
              width: 254px;
              height: 88px;
              border: 1px solid #F9FAFF;
              padding: 12px 15px;
              display: flex;
              background: #FFFDF6;
              border-radius: 13px;
              border: 1px solid rgba(244, 236, 218, 1);
              // border-image: linear-gradient(169deg, rgba(250, 247, 239, 1), rgba(244, 236, 218, 1)) 1 1;

              &:nth-child(3) {
                padding-right: 6px;
              }

              >img {
                .size(37px);
                margin-right: 10px;
              }

              .func-title {
                margin-bottom: 4px;
                font-weight: 600;
                font-size: 14px;
                color: #694E25;
                line-height: 20px;
              }

              .func-desc {
                font-weight: 400;
                font-size: 13px;
                color: #937249;
                line-height: 18px;
              }
            }
          }

          .func-list {

            .left, .right {
              width: 253px;
              height: 204px;
              padding-left: 18px;
              padding-top: 14px;
              background: #FFFDF6;
              border-radius: 9px;
              border: 1px solid;
              border-image: linear-gradient(169deg, rgba(250, 247, 239, 1), rgba(244, 236, 218, 1)) 1 1;

              >.title {
                .flex-row();
                font-weight: 400;
                font-size: 12px;
                color: #736F6C;
                margin-bottom: 18px;
                justify-content: flex-start;
                position: relative;

                .icon {
                  width: 12px;
                  margin-right: 5px;
                }
              }

              ul {
                display: flex;
                flex-wrap: wrap;
                row-gap: 17px;

                li {
                  font-weight: 400;
                  font-size: 14px;
                  color: #353535;
                  display: flex;
                  align-items: baseline;

                  &.learning-li {
                    margin-left: 0px !important;
                  }

                  .icon {
                    .size(12px);
                    margin-right: 6px;
                  }

                  &:nth-child(2n) {
                    margin-left: 40px;
                  }

                  .database-list {
                    display: flex;
                    row-gap: 8px;
                    column-gap: 9px;
                    flex-wrap: wrap;

                    span {
                      background: #FFFFFF;
                      border-radius: 5px;
                      border: 1px solid #F0F0F0;
                      display: block;
                      font-weight: 400;
                      font-size: 12px;
                      color: #6C6F73;
                      line-height: 17px;
                      padding: 2px 8px 3px;
                    }
                  }
                }

              }
            }
          }
        }
      }

      >.right {
        width: 277px;
        position: relative;
        z-index: 2;
        padding: 24px 0 0;
        height: 100%;
        border-radius: 0px 12px 12px 0px;
        .flex-col();
        justify-content: flex-start;

        .right-bg {
          width: 277px;
          position: absolute;
          right: 0;
          top: 0;
          z-index: -1;
        }

        .buy-box {
          .flex-col();

          .plan-title {
            font-weight: 500;
            font-size: 15px;
            color: #FBDEBE;
            line-height: 21px;
          }

          .plan-price {
            position: relative;
            width: fit-content;
            font-weight: bold;
            font-size: 30px;
            color: #FBDEBE;
            line-height: 37px;

            div {
              height: 22px;
              padding: 0 6px;
              position: absolute;
              background: linear-gradient(157deg, #FF834E 0%, #FFAC5C 100%);
              border-radius: 9px 9px 9px 0px;
              border: 1px solid #FFAC5C;
              font-weight: 400;
              font-size: 12px;
              color: #F7F9FF;
              .flex-row();
              right: -7px;
              top: 0;
              transform: translateX(100%);
              white-space: nowrap;
            }
          }

          .qr-box {
            .size(168px);
            margin-top: 8px;
            position: relative;
            z-index: 1;
            .flex-col();

            .qr-logo {
              .size(35px, 36px);
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 2;
            }

            .reload-box {
              .size(160px);
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              background: rgba(255, 255, 255, 0.94);
              .flex-col();
              cursor: pointer;

              img {
                width: 16px;
                margin-bottom: 4px;
              }

              span.text {
                font-size: 13px;
                font-weight: 400;
                color: #999999;
                line-height: 18px;
              }
            }
          }

          .pay-methods {
            .flex-row();
            font-weight: 400;
            font-size: 13px;
            margin-top: 12px;
            color: #FBDEBE;

            img {
              height: 14px;
              margin: 0 4px;
            }
          }

          .subscription-Pdf {
            margin-top: 4px;

            &.hidden {
              visibility: hidden;
            }

            a {
              color: #F2D6B8 !important;
            }
          }

        }

        .coupon-box {
          margin-top: 16px;
          width: 212px;
          height: 38px;
          background: rgba(254, 243, 221, 0.1);
          border-radius: 10px;
          padding: 0 10px;
          .flex-row();
          justify-content: space-between;

          .coupon-info {
            width: 100%;
            .flex-row();
            justify-content: space-between;
          }

          &.enable {
            cursor: pointer;

            .left {
              color: #FBDEBE;
            }
          }

          .right {
            font-weight: 400;
            font-size: 14px;
            color: #FF834E;
            .flex-row();

            img {
              width: 12px;
              margin-left: 2px
            }
          }

          .left {
            .flex-row();

            img {
              width: 18px;
              margin-right: 5px;
            }

            font-weight: 400;
            font-size: 13px;
            color: #FBDEBE;
          }
        }

        .user-comment-box {
          margin-top: 24px;
          .flex-col();

          .text-img {
            height: 27px;
          }

          .comment-list {
            .size(235px, 199px);
            overflow: hidden;
            margin-top: 15px;

            :global {
              .ant-carousel, .slick-slider, .slick-list {
                height: 100% !important;
              }
            }

            .comment-item {
              padding: 10px;
              width: 235px;
              position: relative;
              z-index: 2;
              margin-bottom: 8px;
              border-radius: 10px;
              overflow: hidden;
            }

            .comment-content {
              font-weight: 400;
              font-size: 13px;
              color: #D9C2A8;
              line-height: 18px;
              padding-left: 30px;
            }

            .comment-user {
              .flex-row();
              justify-content: flex-start;
              font-weight: 400;
              font-size: 12px;
              color: #D9C2A8;
              margin-bottom: 5px;

              .avatar {
                .circle(22px);
                margin-right: 8px;
                background: #E0C8AC;
                font-weight: 600;
                font-size: 12px;
                color: #FFFFFF;
                .flex-row();
              }
            }
          }
        }
      }
    }

    .coupon-list-box {
      min-height: 640px;
      margin-top: 24px;
      width: 910px;
      background: rgba(255, 255, 255, 0.76);
      box-shadow: 0px 2px 26px 0px rgba(134, 134, 134, 0.08);
      border-radius: 13px;
      border: 3px solid #FFFFFF;
      padding: 24px 40px;

      >.coupon-list-box-header {
        margin-bottom: 16px;

        >div {
          .flex-row();
          justify-content: flex-start;
          cursor: pointer;
          font-weight: 400;
          font-size: 15px;
          color: #626273;

          .back-btn {
            .circle(24px);
            background: #E8EAF1;
            margin-right: 10px;
            .flex-row();

            img {
              width: 16px;
            }
          }

        }
      }
    }
  }
}